import React, {
    useCallback, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardCard.module.scss'
import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
import Candidacy from 'requests/objects/candidacy'
import EBoardCardType from 'types/missions/enums/boardCardType'
import HandleBlob from 'helpers/methods/blob'
import EBoardColumn from 'types/missions/enums/boardColumn'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import BoardDetailPanel from 'components/pages/missions/[id]/pivotCandidacy/boardDetailPanel'
import BoardFavoriteButton from 'components/pages/missions/[id]/pivotCandidacy/boardFavoriteButton'
import Param from 'requests/objects/param'
import User from 'requests/objects/user'
import Hunt from 'requests/objects/hunt'
import HuntsHandler from 'requests/handlers/huntsHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'

/**
 * Board card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {EBoardColumn} props.columnId columnId
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {(id: number, type: EBoardCardType) => void} props.onDelete onDelete
 * @param {(item: Hunt | Candidacy) => void} props.onCreate onCreate
 * @param {(item: Hunt | Candidacy) => void} props.onUpdate onUpdate
 * @param {Param} props.param param
 * @param {User} props.me me
 * @param {boolean} props.isSendMailEnable isSendMailEnable
 * @param {(email: string) => void} props.toggleEmail toggleEmail
 * @param {string[]} props.selectedEmails selectedEmails
 * @param {(columnId: EBoardColumn) => void} props.setDragingColumnId setDragingColumn
 * @param {Candidacy | Hunt} props.preSelectedBoardItem preSelectedBoardItem
 * @returns {JSX.Element} Returns
 */
export default function BoardCard({
    item, columnId, handler, onDelete, onUpdate, me, onCreate, param, isSendMailEnable, toggleEmail, selectedEmails, setDragingColumnId, preSelectedBoardItem,
}) {
    const [isDetailPanelVisible, setIsDetailPanelVisible] = useState(() => {
        switch (preSelectedBoardItem?.type) {
            case EBoardCardType.CANDIDACY:
                return /** @type {Candidacy} */(preSelectedBoardItem).candidacyId === /** @type {Candidacy} */(item).candidacyId
            case EBoardCardType.HUNT:
                return /** @type {Hunt} */(preSelectedBoardItem).huntId === /** @type {Hunt} */(item).huntId
            default:
                return null
        }
    })

    const isEmailSelected = useMemo(() => selectedEmails.includes(item.candidate?.email), [item?.candidate?.email, selectedEmails])

    const onClick = useCallback(() => {
        if (isSendMailEnable)
            toggleEmail(item?.candidate?.email)
    }, [isSendMailEnable, item?.candidate?.email, toggleEmail])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getFileCv(
                /** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId,
                item.fileCv?.fileCvId,
            )
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, item.fileCv?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, item])

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <article
                id={(/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)?.toString()}
                className={classNames(
                    styles['board-card'],
                    { [styles['is-orange']]: item.isRejected },
                    { [styles['is-yellow']]: item.isUnderProposal },
                    { [styles['is-grey-blue']]: item.type === EBoardCardType.CANDIDACY && !item.isRejected },
                    { [styles['is-purple']]: item.type === EBoardCardType.HUNT && !item.isRejected },
                    {
                        [styles['is-green']]: item.isCallDone && !item.isRejected && !item.isUnderProposal
                            && item.boardColumnId >= EBoardColumn.CANDIDIDATE_INTERVIEW,
                    },
                    { [styles['is-grey']]: !item.isRejected && (item.isInterviewCancel || item.isInterviewClientCancel) },
                    { [styles['is-clickable']]: isSendMailEnable },
                )}
                draggable={!item.isRejected && !isSendMailEnable}
                onDragStart={ev => {
                    ev.dataTransfer.setData('id', /** @type {HTMLElement} */(ev.target).id)
                    ev.dataTransfer.setData('type', item.type.toString())
                    setDragingColumnId(columnId)
                }}
                onDragEnd={() => setDragingColumnId(null)}
                onClick={onClick}
                onKeyDown={onClick}
            >
                <div className={styles['board-card-header']}>
                    {!isSendMailEnable && (
                        <BoardFavoriteButton
                            handler={handler}
                            item={item}
                            onUpdate={onUpdate}
                        />
                    )}
                    {isSendMailEnable && (
                        <Icon
                            className={classNames(
                                styles['board-card-header-checked'],
                                { [styles['is-active']]: isEmailSelected },
                                { [styles['is-disabled']]: !item?.candidate?.email },
                            )}
                            // eslint-disable-next-line no-nested-ternary
                            iconName={item?.candidate?.email ? (isEmailSelected ? 'CheckboxCompositeReversed' : 'Checkbox') : 'CheckboxFill'}
                        />
                    )}
                    <Text className={styles['board-card-header-type']}>
                        {item.type === EBoardCardType.CANDIDACY
                            ? (
                                <Text variant="smallPlus">
                                    <Icon iconName="TextDocument" />
                                    {' '}
                                    Classique
                                </Text>
                            )
                            : ''}
                        {item.type === EBoardCardType.HUNT
                            ? (
                                <Text variant="smallPlus">
                                    <Icon iconName="BullseyeTarget" />
                                    {' '}
                                    AD
                                </Text>
                            )
                            : ''}
                    </Text>
                </div>
                <Text className={styles['board-card-candidate']}>
                    {[
                        item.candidate?.lastname,
                        item.candidate?.firstname,
                    ].filter(x => x).join(' ').trim().toUpperCase() || 'Inconnu'}
                </Text>
                <Text className={styles['board-card-subtitle']}>
                    {/** @type {Hunt} */(item).company || item.candidate?.company || 'Inconnu'}
                </Text>
                <Text className={styles['board-card-subtitle']}>
                    {item.candidate?.currentPosition || 'Inconnu'}
                </Text>
                {item?.currentSalary && (
                    <Text className={styles['board-card-subtitle']}>
                        RA:
                        {' '}
                        {item?.currentSalary || 'Inconnu'}
                    </Text>
                )}
                {item?.desiredSalary && (
                    <Text className={styles['board-card-subtitle']}>
                        RS:
                        {' '}
                        {item?.desiredSalary || 'Inconnu'}
                    </Text>
                )}
                <div className={styles['board-card-buttons']}>
                    <button
                        type="button"
                        className={styles['board-card-buttons-button']}
                        onClick={() => download()}
                        disabled={!item.fileCv?.fileCvId}
                    >
                        <Text variant="smallPlus">
                            <Icon iconName="DownloadDocument" />
                            {' '}
                            CV
                        </Text>
                    </button>
                    <button
                        type="button"
                        className={styles['board-card-buttons-button']}
                        onClick={() => setIsDetailPanelVisible(true)}
                        disabled={isSendMailEnable}
                    >
                        <Text variant="smallPlus">
                            <Icon iconName="ChevronRightMed" />
                            {' '}
                            Détails
                        </Text>
                    </button>
                </div>
            </article>
            <BoardDetailPanel
                handler={handler}
                isVisible={isDetailPanelVisible}
                setIsVisible={setIsDetailPanelVisible}
                item={item}
                me={me}
                onUpdate={onUpdate}
                onCreate={onCreate}
                onDelete={onDelete}
                param={param}
            />
        </>
    )
}
